<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.7458 1.62712H8.93722C8.90739 1.28814 8.80298 0.716475 8.44556 0.349559C8.21939 0.117695 7.93247 0 7.59295 0H4.88136C4.54183 0 4.25492 0.117695 4.02875 0.349559C3.67132 0.716475 3.56692 1.28814 3.53708 1.62712H0.271186C0.121492 1.62712 0 1.74834 0 1.89831C0 2.04827 0.121492 2.16949 0.271186 2.16949H0.824678L1.344 14.6498C1.35403 15.1167 1.64475 16 2.67498 16H10.342C11.3722 16 11.6629 15.1167 11.6727 14.6555L12.1923 2.16949H12.7458C12.8955 2.16949 13.0169 2.04827 13.0169 1.89831C13.0169 1.74834 12.8955 1.62712 12.7458 1.62712ZM4.0678 13.5593C4.0678 13.7093 3.94631 13.8305 3.79661 13.8305C3.64692 13.8305 3.52542 13.7093 3.52542 13.5593V4.61017C3.52542 4.4602 3.64692 4.33898 3.79661 4.33898C3.94631 4.33898 4.0678 4.4602 4.0678 4.61017V13.5593ZM6.77966 13.5593C6.77966 13.7093 6.65817 13.8305 6.50847 13.8305C6.35878 13.8305 6.23729 13.7093 6.23729 13.5593V4.61017C6.23729 4.4602 6.35878 4.33898 6.50847 4.33898C6.65817 4.33898 6.77966 4.4602 6.77966 4.61017V13.5593ZM9.49153 13.5593C9.49153 13.7093 9.37003 13.8305 9.22034 13.8305C9.07064 13.8305 8.94915 13.7093 8.94915 13.5593V4.61017C8.94915 4.4602 9.07064 4.33898 9.22034 4.33898C9.37003 4.33898 9.49153 4.4602 9.49153 4.61017V13.5593ZM4.41817 0.727051C4.53966 0.602847 4.69125 0.542373 4.88136 0.542373H7.59295C7.78305 0.542373 7.93464 0.602847 8.05614 0.727051C8.27444 0.950237 8.36203 1.34292 8.39241 1.62712H4.08217C4.11254 1.34292 4.20014 0.950237 4.41817 0.727051Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>