<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 10 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.23465 1.44488V0.345947L0.355103 2.2347H1.44483C1.65481 2.23625 1.85666 2.15362 2.00505 2.00511C2.15357 1.85671 2.2362 1.65486 2.23465 1.44488Z"/>
      <path d="M8.31433 0H2.72449V1.44487C2.72569 1.7846 2.59128 2.11081 2.35104 2.35104C2.11081 2.59128 1.7846 2.72569 1.44487 2.72449H0V11.0235C0 11.5622 0.449976 12 0.991789 12H8.31732C8.85615 12 9.30923 11.5622 9.30923 11.0235V0.976483C9.30612 0.437779 8.85615 0 8.31433 0ZM2.04492 9.82653H1.14186C1.00662 9.82653 0.896963 9.71688 0.896963 9.58163C0.896963 9.44639 1.00662 9.33673 1.14186 9.33673H2.04492C2.18017 9.33673 2.28982 9.44639 2.28982 9.58163C2.28982 9.71688 2.18017 9.82653 2.04492 9.82653ZM2.04492 7.37755H1.14186C1.00662 7.37755 0.896963 7.2679 0.896963 7.13265C0.896963 6.99741 1.00662 6.88776 1.14186 6.88776H2.04492C2.18017 6.88776 2.28982 6.99741 2.28982 7.13265C2.28982 7.2679 2.18017 7.37755 2.04492 7.37755ZM2.04492 4.92857H1.14186C1.00662 4.92857 0.896963 4.81892 0.896963 4.68367C0.896963 4.54843 1.00662 4.43878 1.14186 4.43878H2.04492C2.18017 4.43878 2.28982 4.54843 2.28982 4.68367C2.28982 4.81892 2.18017 4.92857 2.04492 4.92857ZM8.16737 9.82653H3.28161C3.14636 9.82653 3.03671 9.71688 3.03671 9.58163C3.03671 9.44639 3.14636 9.33673 3.28161 9.33673H8.16426C8.29939 9.33709 8.4088 9.44651 8.40916 9.58163C8.40964 9.64621 8.38453 9.70839 8.33909 9.7543C8.29377 9.80022 8.23194 9.82617 8.16737 9.82653ZM8.16737 7.37755H3.28161C3.14636 7.37755 3.03671 7.2679 3.03671 7.13265C3.03671 6.99741 3.14636 6.88776 3.28161 6.88776H8.16426C8.29939 6.88811 8.4088 6.99753 8.40916 7.13265C8.40964 7.19723 8.38453 7.25941 8.33909 7.30533C8.29377 7.35124 8.23194 7.37719 8.16737 7.37755ZM8.16737 4.92857H3.28161C3.14636 4.92857 3.03671 4.81892 3.03671 4.68367C3.03671 4.54843 3.14636 4.43878 3.28161 4.43878H8.16426C8.29939 4.43913 8.4088 4.54855 8.40916 4.68367C8.40964 4.74825 8.38453 4.81043 8.33909 4.85635C8.29377 4.90226 8.23194 4.92821 8.16737 4.92857Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>