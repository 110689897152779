<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M256 303.992C247.168 303.992 240 296.824 240 287.992C240 279.16 247.168 271.992 256 271.992H288C296.832 271.992 304 264.824 304 255.992C304 247.16 296.832 239.992 288 239.992H272V223.992C272 215.16 264.832 207.992 256 207.992C247.168 207.992 240 215.16 240 223.992V242.936C221.408 249.56 208 267.16 208 287.992C208 314.456 229.536 335.992 256 335.992C264.832 335.992 272 343.192 272 351.992C272 360.792 264.832 367.992 256 367.992H224C215.168 367.992 208 375.16 208 383.992C208 392.824 215.168 399.992 224 399.992H240V415.992C240 424.824 247.168 431.992 256 431.992C264.832 431.992 272 424.824 272 415.992V397.048C290.592 390.424 304 372.824 304 351.992C304 325.528 282.464 303.992 256 303.992Z"/>
      <path d="M496 191.992C504.832 191.992 512 184.824 512 175.992V143.992C512 138.072 508.736 132.664 503.52 129.88L263.52 1.88006C258.816 -0.615943 253.184 -0.615943 248.448 1.88006L8.448 129.88C3.264 132.664 0 138.072 0 143.992V175.992C0 184.824 7.168 191.992 16 191.992H64V447.992H32C14.368 447.992 0 462.328 0 479.992V495.992C0 504.824 7.168 511.992 16 511.992H496C504.832 511.992 512 504.824 512 495.992V479.992C512 462.328 497.664 447.992 480 447.992H448V191.992H496V191.992ZM256 63.9921C273.664 63.9921 288 78.3281 288 95.9921C288 113.656 273.664 127.992 256 127.992C238.336 127.992 224 113.656 224 95.9921C224 78.3281 238.336 63.9921 256 63.9921ZM384 447.992H128V191.992H384V447.992Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>