<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.01038 0H0.947884C0.425194 0 0 0.425194 0 0.947884V3.38538C0 3.90817 0.425194 4.33337 0.947884 4.33337H5.01038C5.53317 4.33337 5.95837 3.90817 5.95837 3.38538V0.947884C5.95837 0.425194 5.53317 0 5.01038 0Z"/>
      <path d="M5.01038 5.41663H0.947884C0.425194 5.41663 0 5.84182 0 6.36461V12.0521C0 12.5748 0.425194 13 0.947884 13H5.01038C5.53317 13 5.95837 12.5748 5.95837 12.0521V6.36461C5.95837 5.84182 5.53317 5.41663 5.01038 5.41663V5.41663Z"/>
      <path d="M12.0521 8.66663H7.98961C7.46682 8.66663 7.04163 9.09182 7.04163 9.61461V12.0521C7.04163 12.5748 7.46682 13 7.98961 13H12.0521C12.5748 13 13 12.5748 13 12.0521V9.61461C13 9.09182 12.5748 8.66663 12.0521 8.66663V8.66663Z"/>
      <path d="M12.0521 0H7.98961C7.46682 0 7.04163 0.425194 7.04163 0.947884V6.63538C7.04163 7.15817 7.46682 7.58337 7.98961 7.58337H12.0521C12.5748 7.58337 13 7.15817 13 6.63538V0.947884C13 0.425194 12.5748 0 12.0521 0V0Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>