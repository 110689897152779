<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 256 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M128 102.4C156.277 102.4 179.2 79.477 179.2 51.2C179.2 22.923 156.277 0 128 0C99.7231 0 76.8 22.923 76.8 51.2C76.8 79.477 99.7231 102.4 128 102.4Z"/>
      <path d="M213.333 119.467H42.667C19.103 119.467 0 138.57 0 162.134V290.134C0 304.272 11.461 315.734 25.6 315.734C39.738 315.734 51.2 304.273 51.2 290.134V179.2H68.267V486.4C68.267 500.538 79.729 512 93.867 512C108.005 512 119.467 500.539 119.467 486.4V315.733H136.534V486.4C136.534 500.538 147.996 512 162.134 512C176.272 512 187.734 500.539 187.734 486.4V179.2H204.8V290.133C204.8 304.271 216.262 315.733 230.4 315.733C244.538 315.733 256 304.272 256 290.133V162.133C256 138.569 236.898 119.467 213.333 119.467Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>