<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 4.71216H0.429289C0.193305 4.71216 0.00251046 4.90295 0 5.14145V11.5682C0 11.8067 0.193305 11.9975 0.429289 11.9975H3C3.23849 11.9975 3.42929 11.8042 3.42929 11.5682V5.14145C3.42929 4.90295 3.23598 4.71216 3 4.71216Z"/>
      <path d="M7.2854 0H4.71469C4.47871 0 4.2854 0.190795 4.2854 0.426778V11.5682C4.2854 11.8067 4.47871 11.9975 4.71469 11.9975H7.2854C7.52389 11.9975 7.71469 11.8042 7.71469 11.5682V0.429289C7.71469 0.190795 7.52138 0 7.2854 0Z"/>
      <path d="M11.5707 3.42676H8.99997C8.76147 3.42676 8.57068 3.61755 8.57068 3.85605V11.5682C8.57068 11.8067 8.76398 11.9975 8.99997 11.9975H11.5707C11.8092 11.9975 12 11.8042 12 11.5682V3.85605C12 3.61755 11.8067 3.42676 11.5707 3.42676Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>