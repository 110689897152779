<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.79833 6.804L12.8771 0.726458C12.9609 0.64392 13 0.53681 13 0.42844C13 0.32007 12.9609 0.2117 12.8771 0.130422L12.8696 0.122862C12.7908 0.0428441 12.6837 0 12.5722 0H9.62226C9.50885 0 9.403 0.0428441 9.32424 0.122862L6.50158 2.94489L3.67891 0.122862C3.59827 0.0428441 3.49053 0 3.38089 0H0.43096C0.316289 0 0.21107 0.0428441 0.131682 0.122862L0.123491 0.130422C0.042844 0.20792 0 0.31755 0 0.42844C0 0.53933 0.042844 0.647701 0.123491 0.726458L6.2023 6.804C6.36863 6.96971 6.63452 6.96971 6.79833 6.804Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>