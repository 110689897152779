var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shadow rounded relative"},[(_vm.icon)?_c('div',{staticClass:"absolute-center-y",class:{
      'left-0 pl-2': _vm.icon.position === 'left',
      'right-0 pr-2': !_vm.icon.position || _vm.icon.position === 'right'
    }},[_c(`Icon-${_vm.icon.name}`,{tag:"component",class:_vm.icon.color ? `text-${_vm.icon.color}` : 'text-neutral-700',attrs:{"size":"smallest"}})],1):_vm._e(),_c('the-mask',{staticClass:"px-2 border-l-4 border-solid bg-white rounded py-1 w-full transition focus:shadow focus:border-r-4 font-light",class:[_vm.error ? 'border-error-base' : 'border-primary-200', {
      'pl-8': _vm.icon && (_vm.icon.position === 'left'),
      'pr-8': _vm.icon && (!_vm.icon.position || _vm.icon.position === 'right'),
    }, _vm.inputClass],attrs:{"value":_vm.value,"mask":_vm.mask,"placeholder":_vm.placeholder,"masked":_vm.masked,"inputmode":_vm.inputMode},on:{"input":_vm.onInput}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }