<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.88 1.37055C16.0009 1.70835 15.754 2.06452 15.3953 2.06452H0.604742C0.245935 2.06452 -0.000935487 1.70835 0.12 1.37055C0.405645 0.572484 1.16948 0 2.06452 0H13.9355C14.8305 0 15.5944 0.572484 15.88 1.37055ZM15.4839 3.09677H0.516129C0.231064 3.09677 0 3.32784 0 3.6129V3.87097C0 4.15603 0.231064 4.3871 0.516129 4.3871H15.4839C15.7689 4.3871 16 4.15603 16 3.87097V3.6129C16 3.32784 15.7689 3.09677 15.4839 3.09677ZM0.516129 5.41935H15.4839C15.7689 5.41935 16 5.65042 16 5.93548V9.80645C16 10.9466 15.0757 11.871 13.9355 11.871H2.06452C0.924322 11.871 0 10.9466 0 9.80645V5.93548C0 5.65042 0.231064 5.41935 0.516129 5.41935ZM5.41935 7.74194C5.41935 7.03045 4.84052 6.45161 4.12903 6.45161H3.6129C2.90142 6.45161 2.32258 7.03045 2.32258 7.74194V8.25806C2.32258 8.96955 2.90142 9.54839 3.6129 9.54839H4.12903C4.84052 9.54839 5.41935 8.96955 5.41935 8.25806V7.74194ZM11.3548 9.03226C11.3548 8.74719 11.1238 8.51613 10.8387 8.51613H8.51613C8.23106 8.51613 8 8.74719 8 9.03226C8 9.31732 8.23106 9.54839 8.51613 9.54839H10.8387C11.1238 9.54839 11.3548 9.31732 11.3548 9.03226ZM13.6774 6.96774C13.6774 6.68268 13.4464 6.45161 13.1613 6.45161H8.51613C8.23106 6.45161 8 6.68268 8 6.96774C8 7.25281 8.23106 7.48387 8.51613 7.48387H13.1613C13.4464 7.48387 13.6774 7.25281 13.6774 6.96774Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>
