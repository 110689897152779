<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 11 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.27378 0C4.69215 0 4.21899 0.473156 4.21899 1.05478V5.29734C4.21899 5.87897 4.69215 6.35212 5.27378 6.35212C5.8554 6.35212 6.32856 5.87897 6.32856 5.29734V1.05478C6.32856 0.473156 5.8554 0 5.27378 0Z" />
      <path d="M8.80347 2.78806C8.35958 2.38768 7.71358 2.43321 7.32594 2.83196C6.93613 3.23295 6.90601 3.9121 7.39984 4.36234C8.18359 5.07472 8.57976 6.15435 8.39209 7.24347C8.13737 8.72161 6.85185 9.88646 5.27373 9.88993C3.65723 9.89349 2.41883 8.7 2.16339 7.29733C1.96424 6.2017 2.33165 5.10468 3.14711 4.36302C3.63953 3.91473 3.61382 3.23258 3.2229 2.83114C2.83462 2.43239 2.18474 2.38932 1.73697 2.79458C0.496913 3.91777 -0.150384 5.57823 0.0297097 7.26131C0.316069 9.93736 2.57554 12 5.28616 12C7.96567 12 10.1596 9.93242 10.4979 7.43568C10.7338 5.69591 10.1003 3.95853 8.80347 2.78806Z" />
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>