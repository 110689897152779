<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 371 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M185.427 0C83.182 0 0 83.182 0 185.426C0 312.314 165.939 498.593 173.004 506.461C179.64 513.852 191.226 513.839 197.85 506.461C204.915 498.593 370.854 312.314 370.854 185.426C370.852 83.182 287.671 0 185.427 0ZM185.427 278.719C133.985 278.719 92.135 236.868 92.135 185.426C92.135 133.984 133.986 92.134 185.427 92.134C236.868 92.134 278.718 133.985 278.718 185.427C278.718 236.869 236.868 278.719 185.427 278.719Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>