var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"w-full justify-between flex items-center"},[_c('MB-Title',{attrs:{"text":"Dashboard"}})],1),_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-1/2 pr-3 mb-6 lg:w-full lg:pr-0"},[_c('Card',{attrs:{"header":{
          title: 'Faturamento e Impostos',
          icon: 'Taxes'
        }}},[_c('div',{staticClass:"p-4 w-full"},[_c('Faturamento-Impostos-Chart')],1)])],1),_c('div',{staticClass:"w-1/2 mb-6 pl-3 lg:w-full lg:pl-0"},[_c('Card',{attrs:{"header":{
          title: 'Guias',
          icon: 'Document'
        }}},[_c('div',{staticClass:"flex flex-col p-4 pb-0 w-full"},[(_vm.loadingGuias)?_c('div',{staticClass:"flex justify-center pb-4"},[_c('MB-Spinner',{attrs:{"size":"12"}})],1):_vm._e(),(!_vm.guias.length && !_vm.loadingGuias)?_c('div',{staticClass:"pb-4"},[_c('Not-Found-Data')],1):_vm._e(),_vm._l((_vm.guias),function(guia){return _c('a',{key:guia.id,staticClass:"rounded hover:bg-neutral-700 transition hover:text-neutral-100 border-neutral-700 border-2 px-2 py-1 flex justify-between w-full mb-4 flex-grow items-center",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onDownloadGuia(guia)}}},[_c('span',[_vm._v(_vm._s(guia.tipo)+" - "+_vm._s(_vm.moment(guia.data_vencimento).format('MM/YYYY')))]),_c('Icon-Download',{staticClass:"ml-4"})],1)})],2)])],1),_c('div',{staticClass:"mb-6 w-full"},[_c('Card',{attrs:{"header":{
          title: 'Faturamentos',
          icon: 'Money'
        }}},[_c('div',{staticClass:"p-4 w-full"},[_c('Faturamentos-Chart')],1)])],1)]),_c('Modal',{attrs:{"opened":_vm.popup},on:{"close-modal":_vm.onCloseModal},scopedSlots:_vm._u([{key:"target",fn:function(){return [_c('div',{staticClass:"px-8 flex flex-col"},[_c('div',{staticClass:"w-full px-24 pt-16 sm:px-12 xs:px-4"},[_c('SVG-Popup-Credit-Card')],1),_c('p',{staticClass:"py-8 text-3xl font-display font-bold text-center leading-tight text-primary-700 sm:text-2xl xs:text-xl"},[_vm._v("Agora você poderá pagar os honorários no cartão de crédito e ganhar "),_c('span',{staticClass:"bg-primary-700 p-1 text-neutral-100"},[_vm._v("milhas.")])]),_c('router-link',{staticClass:"self-center",attrs:{"to":"/payment/tokenize"}},[_c('MB-Button',{staticClass:"text-xl sm:text-lg xs:text-base",attrs:{"text":"Cadaste-se já"}})],1),_c('p',{staticClass:"py-8 text-lg font-light xs:text-base"},[_c('span',{staticClass:"font-bold"},[_vm._v("Obs: ")]),_vm._v("o débito só irá ocorrer no próximo mês")])],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }