<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 319 392" xmlns="http://www.w3.org/2000/svg">
      <path d="M275.5 146.005V105.259C275.5 47.2215 223.459 0 159.5 0C95.5405 0 43.5 47.2215 43.5 105.259V146.005C18.8427 150.259 0 171.776 0 197.67V339.53C0 368.458 23.5118 392 52.4103 392H266.59C295.488 392 319 368.458 319 339.523V197.662C319 171.776 300.157 150.259 275.5 146.005ZM58 105.259C58 55.2212 103.53 14.5185 159.5 14.5185C215.47 14.5185 261 55.2212 261 105.259V145.185H58V105.259ZM188.5 275.852C188.5 291.866 175.494 304.889 159.5 304.889C143.506 304.889 130.5 291.866 130.5 275.852V232.296C130.5 216.282 143.506 203.259 159.5 203.259C175.494 203.259 188.5 216.282 188.5 232.296V275.852Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>