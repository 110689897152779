<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C3.58862 0 0 3.58862 0 8C0 12.4114 3.58862 16 8 16C12.4114 16 16 12.4114 16 8C16 3.58862 12.4114 0 8 0ZM8.61538 12.6154C8.61538 12.9551 8.33969 13.2308 8 13.2308C7.66031 13.2308 7.38462 12.9551 7.38462 12.6154V12C7.38462 11.6603 7.66031 11.3846 8 11.3846C8.33969 11.3846 8.61538 11.6603 8.61538 12V12.6154ZM9.49969 7.55908C8.95446 7.91108 8.61538 8.57015 8.61538 9.27939V10.1538C8.61538 10.4935 8.33969 10.7692 8 10.7692C7.66031 10.7692 7.38462 10.4935 7.38462 10.1538V9.27939C7.38462 8.13908 7.92615 7.10923 8.83262 6.52492C9.28185 6.23508 9.54554 5.74308 9.53815 5.20892C9.52708 4.39815 8.83262 3.70369 8.02185 3.69262C7.60154 3.672 7.21508 3.844 6.91969 4.13508C6.62431 4.42677 6.46154 4.81569 6.46154 5.23077C6.46154 5.57046 6.18585 5.84615 5.84615 5.84615C5.50646 5.84615 5.23077 5.57046 5.23077 5.23077C5.23077 4.48369 5.52369 3.78369 6.05569 3.25908C6.57785 2.744 7.26677 2.46154 7.99938 2.46154C8.01262 2.46154 8.02585 2.46185 8.03938 2.46185C9.52369 2.48246 10.7483 3.70708 10.7689 5.19138C10.7825 6.15262 10.308 7.03785 9.49969 7.55908Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>