<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M506.142 5.85825C498.331 -1.95275 485.668 -1.95275 477.858 5.85825L422.322 61.3943C375.983 21.6903 317.561 0.000250101 256 0.000250101C187.62 0.000250101 123.333 26.6283 74.9803 74.9803C26.6273 123.332 0.000250101 187.62 0.000250101 256C0.000250101 317.619 21.6712 376 61.3843 422.332L5.85825 477.858C-1.95275 485.669 -1.95275 498.332 5.85825 506.142C9.76325 510.047 14.8812 512 20.0002 512C25.1192 512 30.2373 510.047 34.1423 506.142L506.142 34.1423C513.953 26.3323 513.953 13.6682 506.142 5.85825V5.85825ZM40.0003 256C40.0003 136.897 136.897 40.0003 256 40.0003C306.888 40.0003 355.231 57.5672 393.937 89.7793L89.7783 393.938C57.5603 355.238 40.0003 306.934 40.0003 256V256ZM487.428 146.43C503.733 180.813 512 217.677 512 256C512 306.531 497.001 355.674 468.626 398.116C464.77 403.884 458.435 407.002 451.981 407.002C448.16 407.002 444.298 405.91 440.883 403.627C431.7 397.488 429.233 385.067 435.372 375.885C459.335 340.044 472 298.589 472 256C472 223.652 465.031 192.554 451.286 163.57C446.553 153.59 450.807 141.662 460.787 136.929C470.769 132.194 482.695 136.45 487.428 146.43V146.43ZM395.206 447.639C400.929 457.086 397.909 469.385 388.461 475.107C348.617 499.243 302.813 512 256 512C218.511 512 182.377 504.075 148.601 488.445C138.577 483.806 134.211 471.919 138.85 461.895C143.489 451.871 155.376 447.507 165.4 452.144C193.873 465.319 224.355 472 256 472C295.505 472 334.143 461.244 367.738 440.894C377.184 435.17 389.483 438.19 395.206 447.639Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>