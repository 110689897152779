<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M256 0C114.51 0 0 114.497 0 256C0 397.49 114.497 512 256 512C397.49 512 512 397.503 512 256C512 114.51 397.503 0 256 0ZM273.063 370.811C273.063 380.236 265.422 387.878 255.996 387.878C246.57 387.878 238.929 380.237 238.929 370.811V226.843C238.929 217.418 246.57 209.776 255.996 209.776C265.422 209.776 273.063 217.417 273.063 226.843V370.811ZM256 192.389C237.179 192.389 221.867 177.077 221.867 158.256C221.867 139.435 237.179 124.123 256 124.123C274.821 124.123 290.133 139.435 290.133 158.256C290.133 177.077 274.821 192.389 256 192.389Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>