<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 386 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M105.286 127.77L41.076 152.63C33.346 155.62 29.506 164.3 32.496 172.03C35.498 179.797 44.232 183.588 51.896 180.6L92.506 164.89L107.156 135.37L105.286 127.77Z"/>
      <path d="M384.286 366.49C382.683 351.034 378.342 335.734 371.226 321.4L287.116 152H125.806L92.506 164.89L14.796 321.4C7.75599 335.57 3.35599 350.74 1.72599 366.48C-6.43701 444.546 54.979 512 132.756 512H253.256C331.299 512 392.355 444.267 384.286 366.49ZM178.006 311H208.006C235.576 311 258.006 333.43 258.006 361C258.006 388.57 235.576 411 208.006 411V426C208.006 434.28 201.286 441 193.006 441C184.726 441 178.006 434.28 178.006 426V411H143.006C134.726 411 128.006 404.28 128.006 396C128.006 387.72 134.726 381 143.006 381C150.072 381 196.743 381 208.006 381C219.036 381 228.006 372.03 228.006 361C228.006 349.97 219.036 341 208.006 341H178.006C150.436 341 128.006 318.57 128.006 291C128.006 263.43 150.436 241 178.006 241V226C178.006 217.72 184.726 211 193.006 211C201.286 211 208.006 217.72 208.006 226V241H243.006C251.286 241 258.006 247.72 258.006 256C258.006 264.28 251.286 271 243.006 271H178.006C166.976 271 158.006 279.97 158.006 291C158.006 302.03 166.976 311 178.006 311Z"/>
      <path d="M105.286 127.77L117.596 123.01C119.316 122.34 121.156 122 123.006 122H282.146L307.576 18.58C308.676 14.11 307.656 9.37 304.816 5.75C301.966 2.12 297.616 0 293.006 0H93.006C88.396 0 84.046 2.12 81.196 5.75C78.356 9.37 77.336 14.11 78.436 18.58L105.286 127.77Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>