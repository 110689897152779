<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.63882 24C11.7514 24 13.387 23.8015 14.699 23.3958C14.6712 23.2493 14.6525 23.0995 14.6525 22.9453C14.6525 22.2565 14.9442 21.6346 15.4105 21.1875C14.9442 20.7404 14.6525 20.1185 14.6525 19.4297C14.6525 18.7408 14.9442 18.119 15.4105 17.6719C14.9442 17.2247 14.6525 16.6029 14.6525 15.9141C14.6525 15.2252 14.9442 14.6034 15.4105 14.1562C14.9442 13.7091 14.6525 13.0873 14.6525 12.3984C14.6525 11.5847 15.0617 10.8675 15.6838 10.4193C15.2578 10.0364 14.8027 9.69324 14.3252 9.3913C14.5192 9.15143 14.6525 8.86121 14.6525 8.53125C14.6525 7.75452 14.0113 7.125 13.22 7.125H6.0576C5.26638 7.125 4.62511 7.75452 4.62511 8.53125C4.62511 8.86047 4.7581 9.14978 4.95097 9.38928C2.1703 11.1478 0.279907 14.3322 0.279907 17.6719C0.279907 23.3443 5.64147 24 9.63882 24ZM9.63117 17.1747C8.4462 17.1747 7.48244 16.2286 7.48244 15.0654C7.48244 14.1471 8.08677 13.3724 8.92257 13.0833V12.0469C8.92257 11.6583 9.24302 11.3438 9.63882 11.3438C10.0346 11.3438 10.3551 11.6583 10.3551 12.0469V13.088C11.183 13.3812 11.7799 14.1528 11.7799 15.0654C11.7799 15.4541 11.4595 15.7685 11.0637 15.7685C10.6677 15.7685 10.3474 15.4541 10.3474 15.0654C10.3474 14.6774 10.0262 14.3622 9.63117 14.3622C9.23593 14.3622 8.91493 14.6774 8.91493 15.0654C8.91493 15.4534 9.23593 15.7685 9.63117 15.7685C10.816 15.7685 11.7799 16.7148 11.7799 17.8779C11.7799 18.7905 11.183 19.5621 10.3551 19.8554V20.4844C10.3551 20.8729 10.0346 21.1875 9.63882 21.1875C9.24302 21.1875 8.92257 20.8729 8.92257 20.4844V19.86C8.08677 19.571 7.48244 18.7961 7.48244 17.8779C7.48244 17.4893 7.8027 17.1747 8.19868 17.1747C8.59448 17.1747 8.91493 17.4893 8.91493 17.8779C8.91493 18.2659 9.23593 18.581 9.63117 18.581C10.0262 18.581 10.3474 18.2659 10.3474 17.8779C10.3474 17.4899 10.0262 17.1747 9.63117 17.1747Z"/>
      <path d="M23.6533 21.8906H17.1593C16.566 21.8906 16.085 22.3629 16.085 22.9453C16.085 23.5278 16.566 24 17.1593 24H23.6533C24.2466 24 24.7276 23.5278 24.7276 22.9453C24.7276 22.3629 24.2466 21.8906 23.6533 21.8906Z"/>
      <path d="M23.6533 18.375H17.1593C16.566 18.375 16.085 18.8472 16.085 19.4297C16.085 20.0121 16.566 20.4844 17.1593 20.4844H23.6533C24.2466 20.4844 24.7276 20.0121 24.7276 19.4297C24.7276 18.8472 24.2466 18.375 23.6533 18.375Z"/>
      <path d="M23.6533 14.8594H17.1593C16.566 14.8594 16.085 15.3316 16.085 15.9141C16.085 16.4965 16.566 16.9688 17.1593 16.9688H23.6533C24.2466 16.9688 24.7276 16.4965 24.7276 15.9141C24.7276 15.3316 24.2466 14.8594 23.6533 14.8594Z"/>
      <path d="M23.6533 11.3438H17.1593C16.566 11.3438 16.085 11.816 16.085 12.3984C16.085 12.9809 16.566 13.4531 17.1593 13.4531H23.6533C24.2466 13.4531 24.7276 12.9809 24.7276 12.3984C24.7276 11.816 24.2466 11.3438 23.6533 11.3438Z"/>
      <path d="M11.6556 1.40625C11.3588 0.5896 10.5713 0 9.63871 0C8.70498 0 7.90835 0.587769 7.61234 1.40625H5.34125C5.11109 1.40625 4.89491 1.51465 4.76005 1.69812C4.62576 1.88141 4.58864 2.11761 4.66213 2.33185L5.78033 5.71875H13.4973L14.6153 2.33185C14.6888 2.11761 14.6517 1.88141 14.5174 1.69812C14.3825 1.51465 14.1663 1.40625 13.9362 1.40625H11.6556Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>