<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 282 286" xmlns="http://www.w3.org/2000/svg">
      <path d="M278.09 235.505C277.954 233.733 277.162 232.029 275.863 230.758L163.62 118.515C168.036 108.112 170.367 96.846 170.367 85.203C170.367 62.449 161.492 41.04 145.429 24.937C129.343 8.866 107.95 0 85.1825 0C62.4045 0 41.0475 8.869 24.9355 24.95C-8.3075 58.175 -8.3145 112.216 24.9305 145.438C41.0415 161.553 62.4055 170.428 85.1715 170.428C96.8175 170.428 108.055 168.078 118.483 163.656L155.357 200.558C156.891 202.073 158.914 202.877 161.097 202.806L181.192 202.101L180.536 222.246C180.474 224.371 181.241 226.439 182.781 227.952C184.265 229.464 186.35 230.286 188.466 230.2L208.635 229.511L207.911 249.634C207.848 251.761 208.665 253.833 210.149 255.346C211.683 256.858 213.47 257.671 215.889 257.597L236.008 256.913L235.334 277.039C235.216 279.271 236.156 281.418 237.752 282.942C239.224 284.281 241.061 285.002 242.997 285.002C243.275 285.002 243.56 284.99 243.844 284.965L274.695 281.539C278.864 281.084 281.9 277.364 281.542 273.186L278.09 235.505ZM74.8915 74.915C65.4155 84.381 50.0955 84.381 40.6395 74.915C31.1695 65.446 31.1695 50.129 40.6395 40.669C50.0955 31.209 65.4105 31.2 74.8915 40.666C84.3485 50.125 84.3425 65.452 74.8915 74.915ZM251.755 238.075C250.629 239.201 249.12 239.763 247.654 239.763C246.188 239.763 244.678 239.2 243.553 238.075L148.052 142.546C150.711 139.679 153.129 136.661 155.325 133.488L251.754 229.898C254.006 232.15 254.006 235.824 251.755 238.075Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>