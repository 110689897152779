<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 316 227" xmlns="http://www.w3.org/2000/svg">
      <path d="M293.709 0.871948H21.756C9.741 0.871948 0 10.6139 0 22.6289V204.838C0 216.853 9.741 226.594 21.756 226.594H293.709C305.724 226.594 315.465 216.853 315.465 204.838V22.6289C315.466 10.6139 305.724 0.871948 293.709 0.871948ZM141.644 50.6069H212.123C216.632 50.6069 220.282 54.2619 220.282 58.7659C220.282 63.2699 216.632 66.9249 212.123 66.9249H141.644C137.135 66.9249 133.485 63.2699 133.485 58.7659C133.486 54.2619 137.141 50.6069 141.644 50.6069ZM112.404 174.993H35.348V52.5429H112.403V174.993H112.404ZM281.472 175.939H141.644C137.135 175.939 133.485 172.284 133.485 167.78C133.485 163.276 137.135 159.621 141.644 159.621H281.471C285.98 159.621 289.63 163.276 289.63 167.78C289.63 172.29 285.975 175.939 281.472 175.939ZM281.472 146.704H141.644C137.135 146.704 133.485 143.049 133.485 138.545C133.485 134.041 137.135 130.386 141.644 130.386H281.471C285.98 130.386 289.63 134.041 289.63 138.545C289.63 143.055 285.975 146.704 281.472 146.704ZM281.472 117.469H141.644C137.135 117.469 133.485 113.814 133.485 109.31C133.485 104.806 137.135 101.151 141.644 101.151H281.471C285.98 101.151 289.63 104.806 289.63 109.31C289.63 113.82 285.975 117.469 281.472 117.469Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>