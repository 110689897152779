<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3954 14.5459H2.60455C1.66445 14.5459 0.822548 14.0598 0.352537 13.2457C-0.117473 12.4316 -0.117552 11.4594 0.352537 10.6452L5.74801 1.30018C6.21802 0.486089 7.05992 0 8.00002 0C8.94012 0 9.78203 0.486089 10.252 1.30018L15.6474 10.6452C16.1175 11.4594 16.1175 12.4315 15.6474 13.2457C15.1773 14.0598 14.3354 14.5459 13.3954 14.5459ZM8.00002 0.747342C7.33009 0.747342 6.7302 1.09375 6.39522 1.67385L0.999775 11.0189C0.664823 11.5991 0.664875 12.2918 0.999775 12.872C1.33468 13.4521 1.93465 13.7985 2.60458 13.7985H13.3953C14.0653 13.7985 14.6652 13.4521 15.0001 12.872C15.3351 12.2919 15.3351 11.5991 15.0001 11.0189L9.60475 1.67385C9.26985 1.09367 8.66995 0.747342 8.00002 0.747342ZM14.5226 11.0612L9.32022 2.05029C9.04467 1.57296 8.55113 1.28807 8.00002 1.28807C7.44884 1.28807 6.9553 1.57304 6.67974 2.05029L1.47732 11.0612C1.20168 11.5385 1.20176 12.1084 1.47732 12.5856C1.75287 13.0629 2.24641 13.3478 2.7976 13.3478H13.2024C13.7536 13.3478 14.2472 13.0629 14.5227 12.5856C14.7983 12.1084 14.7982 11.5384 14.5226 11.0612ZM7.05783 4.86214C7.05783 4.34267 7.48047 3.92003 7.99994 3.92003C8.51942 3.92003 8.94206 4.34267 8.94206 4.86214V7.91956C8.94206 8.43906 8.51942 8.86168 7.99994 8.86168C7.48047 8.86168 7.05783 8.43904 7.05783 7.91956V4.86214ZM7.99994 11.6407C7.46761 11.6407 7.03451 11.2076 7.03451 10.6752C7.03451 10.1429 7.46761 9.7098 7.99994 9.7098C8.53228 9.7098 8.96538 10.1429 8.96538 10.6752C8.96538 11.2076 8.53228 11.6407 7.99994 11.6407Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>