<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 478 489" xmlns="http://www.w3.org/2000/svg">
      <path d="M172.443 269.311C172.443 263.196 167.483 258.242 161.363 258.242H122.698C116.585 258.242 111.624 263.196 111.624 269.311V307.971C111.624 314.094 116.585 319.05 122.698 319.05H161.363C167.483 319.05 172.443 314.094 172.443 307.971V269.311Z"/>
      <path d="M269.072 269.311C269.072 263.196 264.111 258.242 258.003 258.242H219.333C213.22 258.242 208.259 263.196 208.259 269.311V307.971C208.259 314.094 213.22 319.05 219.333 319.05H258.003C264.111 319.05 269.072 314.094 269.072 307.971V269.311Z"/>
      <path d="M365.706 269.311C365.706 263.196 360.745 258.242 354.632 258.242H315.967C309.847 258.242 304.887 263.196 304.887 269.311V307.971C304.887 314.094 309.847 319.05 315.967 319.05H354.632C360.745 319.05 365.706 314.094 365.706 307.971V269.311Z"/>
      <path d="M172.443 365.95C172.443 359.825 167.483 354.875 161.363 354.875H122.698C116.585 354.875 111.624 359.825 111.624 365.95V404.603C111.624 410.722 116.585 415.677 122.698 415.677H161.363C167.483 415.677 172.443 410.721 172.443 404.603V365.95Z"/>
      <path d="M269.072 365.95C269.072 359.825 264.111 354.875 258.003 354.875H219.333C213.22 354.875 208.259 359.825 208.259 365.95V404.603C208.259 410.722 213.22 415.677 219.333 415.677H258.003C264.111 415.677 269.072 410.721 269.072 404.603V365.95Z"/>
      <path d="M365.706 365.95C365.706 359.825 360.745 354.875 354.637 354.875H315.967C309.847 354.875 304.887 359.825 304.887 365.95V404.603C304.887 410.722 309.847 415.677 315.967 415.677H354.637C360.745 415.677 365.706 410.721 365.706 404.603V365.95Z"/>
      <path d="M434.843 54.354V113.404C434.843 140.094 413.191 161.602 386.505 161.602H356.012C329.324 161.602 307.385 140.094 307.385 113.404V54.142H169.945V113.404C169.945 140.094 148.007 161.602 121.323 161.602H90.824C64.139 161.602 42.488 140.094 42.488 113.404V54.354C19.165 55.057 0 74.356 0 98.077V444.138C0 468.305 19.588 488.153 43.755 488.153H433.575C457.706 488.153 477.33 468.264 477.33 444.138V98.077C477.33 74.356 458.166 55.057 434.843 54.354ZM420.68 422.588C420.68 433.032 412.212 441.505 401.764 441.505H74.733C64.285 441.505 55.817 433.032 55.817 422.588V243.835C55.817 233.387 64.284 224.914 74.733 224.914H401.763C412.211 224.914 420.679 233.387 420.679 243.835L420.68 422.588Z"/>
      <path d="M90.7172 129.945H120.879C130.034 129.945 137.457 122.533 137.457 113.378V16.573C137.457 7.417 130.034 0 120.879 0H90.7172C81.5612 0 74.1392 7.417 74.1392 16.573V113.378C74.1392 122.533 81.5612 129.945 90.7172 129.945Z"/>
      <path d="M355.624 129.945H385.786C394.935 129.945 402.358 122.533 402.358 113.378V16.573C402.359 7.417 394.936 0 385.786 0H355.624C346.47 0 339.047 7.417 339.047 16.573V113.378C339.047 122.533 346.47 129.945 355.624 129.945Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>