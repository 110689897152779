var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"shadow rounded font-display flex items-center border-2 transition hover:shadow-xl justify-center",class:{
    'text-primary-600 border-primary-600': _vm.outlined && !_vm.disabled,
    'bg-primary-600 text-neutral-100 border-primary-600': !_vm.outlined && !_vm.disabled,
    'bg-neutral-600 text-neutral-100 border-neutral-600': _vm.disabled,
    'px-3 py-2': !_vm.size,
    'px-2 py-1 text-sm': _vm.size === 'small',
    'px-4 py-3': _vm.size === 'large',
    'pointer-events-none': _vm.disabled
  },attrs:{"disabled":_vm.disabled || _vm.loading}},[_c('span',[_vm._v(_vm._s(_vm.text))]),(_vm.icon && !_vm.loading)?_c('div',{staticClass:"ml-2"},[_c(`Icon-${_vm.icon}`,{tag:"component"})],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"ml-2"},[_c('MB-Spinner',{attrs:{"color":"primary-100"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }