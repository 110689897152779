<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 10 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.87702 0.3083C8.69375 0.224254 8.47779 0.256959 8.32789 0.391409L7.53693 1.08848C7.51541 1.10754 7.48295 1.10743 7.46155 1.08825L6.38534 0.130196C6.1907 -0.0433985 5.89603 -0.0433985 5.70139 0.130196L4.62615 1.08737C4.60452 1.10661 4.57183 1.10661 4.5502 1.08737L3.47485 0.130196C3.28021 -0.0433701 2.98554 -0.0433701 2.7909 0.130196L1.71455 1.08825C1.69301 1.10743 1.66046 1.10751 1.6388 1.08845L0.847123 0.391409C0.637092 0.205505 0.315404 0.224254 0.128627 0.433304C0.0448712 0.527051 -0.000953881 0.648396 1.50573e-05 0.773827V11.2262C-0.00186582 11.5064 0.224809 11.735 0.506314 11.7369C0.632418 11.7377 0.754333 11.692 0.848548 11.6086L1.63951 10.9115C1.66103 10.8925 1.69349 10.8926 1.71489 10.9117L2.7911 11.8698C2.98574 12.0434 3.28041 12.0434 3.47505 11.8698L4.5504 10.9126C4.57203 10.8934 4.60472 10.8934 4.62635 10.9126L5.7017 11.8698C5.89637 12.0433 6.19101 12.0433 6.38566 11.8698L7.46206 10.9117C7.48361 10.8925 7.51615 10.8925 7.53781 10.9115L8.32949 11.6086C8.53961 11.7944 8.86127 11.7755 9.04796 11.5664C9.13157 11.4727 9.17734 11.3515 9.17643 11.2262V0.773827C9.17911 0.573031 9.06135 0.389934 8.87702 0.3083ZM1.88679 4.07118H4.72481C4.85072 4.07118 4.9528 4.17278 4.9528 4.2981C4.9528 4.42341 4.85072 4.52502 4.72481 4.52502H1.88679C1.76089 4.52502 1.65881 4.42341 1.65881 4.2981C1.65881 4.17278 1.76089 4.07118 1.88679 4.07118ZM7.28965 7.92882H1.88679C1.76089 7.92882 1.65881 7.82722 1.65881 7.7019C1.65881 7.57659 1.76089 7.47498 1.88679 7.47498H7.28965C7.41555 7.47498 7.51763 7.57659 7.51763 7.7019C7.51763 7.82722 7.41555 7.92882 7.28965 7.92882ZM7.28965 6.22692H1.88679C1.76089 6.22692 1.65881 6.12532 1.65881 6C1.65881 5.87468 1.76089 5.77308 1.88679 5.77308H7.28965C7.41555 5.77308 7.51763 5.87468 7.51763 6C7.51763 6.12532 7.41555 6.22692 7.28965 6.22692Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>