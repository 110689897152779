<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M406 241C364.647 241 331 274.647 331 316C331 357.353 364.647 391 406 391C447.353 391 481 357.353 481 316C481 274.647 447.353 241 406 241Z"/>
      <path d="M479.251 391C460.312 409.499 434.498 421 406 421C377.502 421 351.687 409.499 332.749 391C313.217 410.08 301 436.608 301 466V497C301 505.291 307.709 512 316 512H497C505.291 512 512 505.291 512 497V466C512 436.608 498.783 410.08 479.251 391Z"/>
      <path d="M106 0C64.647 0 31 34.647 31 76C31 117.353 64.647 151 106 151C147.353 151 181 117.353 181 76C181 34.647 147.353 0 106 0Z"/>
      <path d="M179.251 151C160.312 169.499 134.498 181 106 181C77.502 181 51.687 169.499 32.749 151C13.217 170.08 0 196.608 0 226V256C0 264.291 6.709 271 15 271H196C204.291 271 211 264.291 211 256V226C211 196.608 198.783 170.08 179.251 151Z"/>
      <path d="M256 61C240.379 61 225.05 63.278 210.081 66.903C210.348 69.95 211 72.885 211 76C211 83.551 210.117 90.886 208.596 97.989C223.874 93.415 239.81 91 256 91C331.688 91 395.473 142.292 414.833 211.894C426.292 212.868 437.346 215.241 447.468 219.614C430.359 129.441 351.074 61 256 61Z"/>
      <path d="M256 421C180.634 421 117.05 370.15 97.3962 301H66.4512C86.8472 386.864 163.99 451 256 451C261.574 451 267.083 450.621 272.577 450.161C273.839 439.457 276.148 429.047 279.87 419.084C272.009 420.222 264.073 421 256 421Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>