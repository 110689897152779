import { render, staticRenderFns } from "./InputRadio.vue?vue&type=template&id=2b8dd0a0&scoped=true&"
import script from "./InputRadio.vue?vue&type=script&lang=js&"
export * from "./InputRadio.vue?vue&type=script&lang=js&"
import style0 from "./InputRadio.vue?vue&type=style&index=0&id=2b8dd0a0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b8dd0a0",
  null
  
)

export default component.exports