<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9126 15.4886L11.2509 10.8205C12.4399 9.67232 13.1765 8.08942 13.1765 6.34369C13.1765 2.8456 10.2212 0 6.58827 0C2.95531 0 0 2.8456 0 6.34369C0 9.84179 2.95531 12.6874 6.58827 12.6874C8.18295 12.6874 9.64649 12.1385 10.7872 11.2277L15.4602 15.9073C15.5217 15.9692 15.6039 16 15.6864 16C15.7648 16 15.8429 15.9719 15.9038 15.9157C16.0286 15.8 16.0324 15.6088 15.9126 15.4886ZM6.58827 12.0832C3.30135 12.0832 0.627455 9.50859 0.627455 6.34369C0.627455 3.17879 3.30135 0.604161 6.58827 0.604161C9.8752 0.604161 12.5491 3.17879 12.5491 6.34369C12.5491 9.50859 9.87488 12.0832 6.58827 12.0832Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>