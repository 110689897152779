<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.2755 5.5211C16.6384 2.3476 13.8096 0 10.5417 0C6.7503 0 3.66665 3.08365 3.66665 6.875C3.66665 7.0299 3.67305 7.1885 3.6868 7.35625C1.6179 7.57625 0 9.33169 0 11.4583C0 13.7326 1.85075 15.5833 4.125 15.5833H8.70835C8.96135 15.5833 9.1667 15.378 9.1667 15.125C9.1667 14.872 8.96135 14.6667 8.70835 14.6667H4.125C2.35585 14.6667 0.916652 13.2275 0.916652 11.4583C0.916652 9.68911 2.3558 8.24996 4.09475 8.24905C4.1149 8.25271 4.17085 8.25821 4.191 8.25821C4.31565 8.23806 4.4541 8.20321 4.543 8.1033C4.6319 8.00246 4.67225 7.86771 4.653 7.73481C4.60535 7.40481 4.58335 7.13165 4.58335 6.87496C4.58335 3.58961 7.25635 0.916609 10.5417 0.916609C13.4851 0.916609 16.0178 3.11476 16.4331 6.02976C16.4652 6.25986 16.7081 6.42026 16.9583 6.41661C19.2326 6.41661 21.0833 8.26736 21.0833 10.5416C21.0833 12.8159 19.2326 14.6666 16.9583 14.6666H12.375C12.122 14.6666 11.9167 14.872 11.9167 15.125C11.9167 15.378 12.122 15.5833 12.375 15.5833H16.9583C19.7386 15.5833 22 13.3219 22 10.5417C22 7.8916 19.9448 5.7127 17.2755 5.5211Z"/>
      <path d="M10.5417 10.0833C10.2887 10.0833 10.0833 10.2886 10.0833 10.5416V18.6018L9.03192 17.5514C8.85317 17.3726 8.56257 17.3726 8.38382 17.5514C8.20507 17.7301 8.20507 18.0207 8.38382 18.1994L10.2172 20.0328C10.3052 20.1199 10.4225 20.1666 10.5417 20.1666C10.6003 20.1666 10.6599 20.1556 10.7168 20.1318C10.8882 20.0612 11 19.8934 11 19.7083V10.5416C11 10.2886 10.7947 10.0833 10.5417 10.0833Z"/>
      <path d="M12.6986 17.5515C12.5198 17.3728 12.2292 17.3728 12.0505 17.5515L10.2171 19.3849C10.0384 19.5636 10.0384 19.8542 10.2171 20.033C10.307 20.1219 10.4243 20.1668 10.5416 20.1668C10.659 20.1668 10.7763 20.1219 10.8652 20.033L12.6986 18.1996C12.8773 18.0208 12.8773 17.7303 12.6986 17.5515Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>