<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 512 360">
      <path d="M492 160H20C8.954 160 0 168.954 0 180C0 191.046 8.954 200 20 200H492C503.046 200 512 191.046 512 180C512 168.954 503.046 160 492 160Z"/>
      <path d="M492 0H20C8.954 0 0 8.954 0 20C0 31.046 8.954 40 20 40H492C503.046 40 512 31.046 512 20C512 8.954 503.046 0 492 0Z"/>
      <path d="M492 320H20C8.954 320 0 328.954 0 340C0 351.046 8.954 360 20 360H492C503.046 360 512 351.046 512 340C512 328.954 503.046 320 492 320Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>