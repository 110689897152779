<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 512 270" xmlns="http://www.w3.org/2000/svg">
      <path d="M508.177 124.995C503.607 119.897 393.682 0 256 0C118.318 0 8.39401 119.897 3.82301 124.995C-1.27499 130.693 -1.27499 139.307 3.82301 145.005C8.39401 150.103 118.32 270 256 270C393.68 270 503.606 150.103 508.177 145.005C513.274 139.307 513.274 130.693 508.177 124.995ZM256 240C198.109 240 151 192.891 151 135C151 77.109 198.109 30 256 30C313.891 30 361 77.109 361 135C361 192.891 313.891 240 256 240Z"/>
      <path d="M271 105C271 89.91 278.491 76.635 289.887 68.47C279.661 63.235 268.255 60 256 60C214.647 60 181 93.647 181 135C181 176.353 214.647 210 256 210C293.024 210 323.668 182.966 329.722 147.642C299.516 157.367 271 134.522 271 105Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>