<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6369 10.0957H7.45582L6.89114 10.6604C6.65311 10.8984 6.33665 11.0295 6.00001 11.0295C5.6634 11.0295 5.34692 10.8984 5.10891 10.6604L4.5442 10.0957H0.363251C0.162566 10.0957 0 10.2582 0 10.4589V11.9121C0 12.1127 0.162566 12.2753 0.363251 12.2753H11.6367C11.8374 12.2753 12 12.1126 12 11.9121V10.459C12.0002 10.2583 11.8376 10.0957 11.6369 10.0957Z"/>
      <path d="M5.21276 10.0957L5.4432 10.3261C5.59694 10.4799 5.79858 10.5568 6.00019 10.5568C6.20182 10.5568 6.40328 10.4799 6.55705 10.3261L6.78748 10.0957L10.4723 6.4108C10.78 6.10315 10.6767 5.85379 10.2418 5.85379H8.74705V0.315042C8.74705 0.140971 8.6059 0 8.43201 0H3.56808C3.39416 0 3.25304 0.140997 3.25304 0.315042V5.85379H1.75831C1.3234 5.85379 1.22006 6.10315 1.52772 6.4108L5.21276 10.0957Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>