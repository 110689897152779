<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.50996 1.422C1.56103 1.01217 1.91097 0.703125 2.32397 0.703125H2.92969C3.12384 0.703125 3.28125 0.545719 3.28125 0.351562C3.28125 0.157406 3.12384 0 2.92969 0H2.32397C1.557 0 0.907076 0.573961 0.812224 1.33505L0.12738 6.83088C0.372162 6.7392 0.618302 6.66103 0.865263 6.59559L1.50996 1.422Z"/>
      <path d="M11.1877 1.33505C11.0929 0.573961 10.4429 0 9.67597 0H9.07025C8.8761 0 8.71869 0.157406 8.71869 0.351562C8.71869 0.545719 8.8761 0.703125 9.07025 0.703125H9.67597C10.089 0.703125 10.4389 1.01217 10.49 1.422L11.1347 6.59557C11.3816 6.66101 11.6278 6.73917 11.8726 6.83086L11.1877 1.33505Z"/>
      <path d="M11.779 7.54859C10.1371 6.89184 8.47221 6.89184 6.83034 7.54859C6.69689 7.60198 6.60938 7.73124 6.60938 7.875V8.62554C6.20639 8.43656 5.79361 8.43656 5.39062 8.62554V7.875C5.39062 7.73124 5.30311 7.60198 5.16963 7.54859C3.52777 6.89184 1.86284 6.89184 0.220969 7.54859C0.0875156 7.60198 0 7.73124 0 7.875V9.77344C0 11.0012 0.998836 12 2.22656 12H3.16406C4.39179 12 5.39062 11.0012 5.39062 9.77344V9.45952C5.81231 9.09973 6.18769 9.09973 6.60938 9.45952V9.77344C6.60938 11.0012 7.60821 12 8.83594 12H9.77344C11.0012 12 12 11.0012 12 9.77344V7.875C12 7.73124 11.9125 7.60198 11.779 7.54859ZM4.6875 9.77344C4.6875 10.6135 4.00409 11.2969 3.16406 11.2969H2.22656C1.38654 11.2969 0.703125 10.6135 0.703125 9.77344V8.11749C1.36683 7.8787 2.03588 7.75781 2.69531 7.75781C3.35475 7.75781 4.0238 7.8787 4.6875 8.11749V9.77344ZM11.2969 9.77344C11.2969 10.6135 10.6135 11.2969 9.77344 11.2969H8.83594C7.99591 11.2969 7.3125 10.6135 7.3125 9.77344V8.11749C7.9762 7.8787 8.64525 7.75781 9.30469 7.75781C9.96412 7.75781 10.6332 7.8787 11.2969 8.11749V9.77344Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>