<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 12 9" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.91934 8.31085C4.08282 8.48004 4.35491 8.48004 4.5241 8.31085C4.68758 8.14737 4.68758 7.87528 4.5241 7.71218L1.45761 4.64568L11.5766 4.64568C11.8125 4.6453 12 4.45781 12 4.22193C12 3.98604 11.8125 3.79246 11.5766 3.79246L1.4576 3.79246L4.5241 0.731679C4.68758 0.562483 4.68758 0.290015 4.5241 0.126916C4.35491 -0.0422805 4.08244 -0.0422805 3.91934 0.126916L0.126897 3.91936C-0.0422997 4.08284 -0.0422996 4.35493 0.126897 4.51802L3.91934 8.31085Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>