<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 461 367" xmlns="http://www.w3.org/2000/svg">
      <path d="M230.149 73.9451L65.9865 209.28C65.9865 209.471 65.9385 209.752 65.8425 210.135C65.7485 210.515 65.6985 210.791 65.6985 210.987V348.028C65.6985 352.976 67.5075 357.264 71.1245 360.875C74.7405 364.488 79.0225 366.306 83.9715 366.306H193.601V256.67H266.698V366.31H376.327C381.275 366.31 385.563 364.496 389.174 360.875C392.791 357.268 394.606 352.977 394.606 348.028V210.987C394.606 210.227 394.502 209.653 394.318 209.28L230.149 73.9451Z"/>
      <path d="M457.123 178.444L394.601 126.482V9.995C394.601 7.332 393.745 5.142 392.027 3.428C390.323 1.716 388.133 0.860001 385.464 0.860001H330.648C327.982 0.860001 325.793 1.716 324.078 3.428C322.367 5.142 321.512 7.333 321.512 9.995V65.668L251.85 7.423C245.766 2.474 238.532 0 230.156 0C221.781 0 214.548 2.474 208.458 7.423L3.17256 178.444C1.26956 179.964 0.226557 182.01 0.0325575 184.58C-0.160443 187.148 0.504557 189.391 2.02956 191.293L19.7306 212.421C21.2556 214.133 23.2516 215.18 25.7266 215.563C28.0116 215.755 30.2966 215.087 32.5816 213.565L230.15 48.823L427.72 213.564C429.246 214.892 431.241 215.555 433.716 215.555H434.574C437.045 215.179 439.037 214.125 440.57 212.417L458.273 191.292C459.795 189.386 460.462 187.147 460.264 184.576C460.069 182.013 459.022 179.967 457.123 178.444Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>