<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 512 436" fill="none">
      <path d="M438.09 235.32H398.494C402.53 246.37 404.735 258.295 404.735 270.724V420.374C404.735 425.556 403.833 430.53 402.192 435.156H467.653C492.106 435.156 511.999 415.262 511.999 390.81V309.229C512 268.476 478.844 235.32 438.09 235.32Z"/>
      <path d="M107.265 270.725C107.265 258.295 109.47 246.371 113.506 235.321H73.91C33.156 235.321 0 268.477 0 309.231V390.812C0 415.264 19.893 435.158 44.346 435.158H109.808C108.167 430.53 107.265 425.557 107.265 420.375V270.725V270.725Z"/>
      <path d="M301.261 196.815H210.739C169.985 196.815 136.829 229.971 136.829 270.725V420.375C136.829 428.538 143.447 435.157 151.611 435.157H360.389C368.553 435.157 375.171 428.539 375.171 420.375V270.725C375.171 229.971 342.015 196.815 301.261 196.815Z"/>
      <path d="M256 0.840027C206.988 0.840027 167.114 40.714 167.114 89.727C167.114 122.972 185.463 152.007 212.561 167.251C225.414 174.481 240.232 178.613 256 178.613C271.768 178.613 286.586 174.481 299.439 167.251C326.538 152.007 344.886 122.971 344.886 89.727C344.886 40.715 305.012 0.840027 256 0.840027Z"/>
      <path d="M99.9181 83.689C63.2631 83.689 33.4431 113.509 33.4431 150.164C33.4431 186.819 63.2631 216.639 99.9181 216.639C109.216 216.639 118.07 214.713 126.113 211.251C140.019 205.264 151.485 194.666 158.58 181.391C163.56 172.074 166.393 161.445 166.393 150.164C166.393 113.51 136.573 83.689 99.9181 83.689Z"/>
      <path d="M412.082 83.689C375.427 83.689 345.607 113.509 345.607 150.164C345.607 161.446 348.44 172.075 353.42 181.391C360.515 194.667 371.981 205.265 385.887 211.251C393.93 214.713 402.784 216.639 412.082 216.639C448.737 216.639 478.557 186.819 478.557 150.164C478.557 113.509 448.737 83.689 412.082 83.689Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>