<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M504.008 223.961C499.887 221.374 467.596 202.03 454.762 196.159L438.859 157.767C443.584 145.072 452.611 109.459 454.023 103.285C455.33 97.561 453.605 91.567 449.453 87.415L424.585 62.548C420.434 58.396 414.438 56.67 408.715 57.978C404.033 59.049 367.489 68.208 354.233 73.142L315.84 57.239C310.222 44.959 291.454 13.432 288.038 7.99298C284.917 3.01998 279.457 0.000976562 273.585 0.000976562H238.415C232.543 0.000976562 227.083 3.01998 223.961 7.99298C221.374 12.114 202.03 44.405 196.159 57.239L157.767 73.142C145.072 68.417 109.459 59.39 103.285 57.978C97.561 56.671 91.567 58.397 87.415 62.548L62.547 87.416C58.395 91.568 56.669 97.562 57.977 103.286C59.048 107.968 68.207 144.512 73.141 157.768L57.238 196.16C44.958 201.778 13.431 220.546 7.992 223.962C3.019 227.083 0 232.543 0 238.415V273.585C0 279.457 3.019 284.917 7.992 288.039C12.113 290.626 44.404 309.97 57.238 315.841L73.141 354.233C68.416 366.928 59.389 402.541 57.977 408.715C56.67 414.439 58.395 420.433 62.547 424.585L87.415 449.453C91.567 453.605 97.563 455.332 103.285 454.023C107.967 452.952 144.511 443.793 157.767 438.859L196.159 454.762C201.777 467.042 220.545 498.569 223.961 504.008C227.083 508.981 232.543 512 238.415 512H273.585C279.457 512 284.917 508.981 288.039 504.008C290.626 499.887 309.97 467.596 315.841 454.762L354.233 438.859C366.928 443.584 402.541 452.611 408.715 454.023C414.441 455.333 420.434 453.605 424.585 449.453L449.453 424.585C453.605 420.433 455.331 414.439 454.023 408.715C452.952 404.033 443.793 367.489 438.859 354.233L454.762 315.841C467.042 310.223 498.569 291.455 504.008 288.039C508.981 284.917 512 279.457 512 273.585V238.415C512 232.543 508.981 227.083 504.008 223.961ZM256 367.605C194.461 367.605 144.395 317.539 144.395 256C144.395 194.461 194.461 144.396 256 144.396C317.539 144.396 367.605 194.462 367.605 256.001C367.605 317.54 317.539 367.605 256 367.605Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>