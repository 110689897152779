<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M396.424 268.344H456.421V412H396.424V268.344Z"/>
      <path d="M282.807 268.344H342.807V412H282.807V268.344Z"/>
      <path d="M169.19 268.344H229.19V412H169.19V268.344Z"/>
      <path d="M55.5768 268.344H115.573V412H55.5768V268.344Z"/>
      <path d="M476.424 223.344V208.344H35.5768V223.344C35.5768 231.629 42.2916 238.344 50.5768 238.344H461.424C469.706 238.344 476.424 231.629 476.424 223.344Z"/>
      <path d="M496.991 442H15.0143C6.72913 442 0.0142822 448.715 0.0142822 457V497C0.0142822 505.285 6.72913 512 15.0143 512H496.991C505.276 512 511.991 505.285 511.991 497V457C511.991 448.715 505.276 442 496.991 442Z"/>
      <path d="M14.9987 178.344H496.999C503.71 178.344 509.604 173.887 511.432 167.43C513.256 160.973 510.577 154.086 504.862 150.57L263.862 2.22656C259.042 -0.742188 252.96 -0.742188 248.135 2.22656L7.13538 150.57C1.42054 154.09 -1.25915 160.973 0.568973 167.43C2.3971 173.887 8.29163 178.344 14.9987 178.344V178.344ZM245.803 88.2266H266.198C274.483 88.2266 281.198 94.9414 281.198 103.227C281.198 111.512 274.483 118.227 266.198 118.227H245.803C237.518 118.227 230.803 111.512 230.803 103.227C230.803 94.9414 237.518 88.2266 245.803 88.2266V88.2266Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>