<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 10 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.56365 5.86879C5.89784 5.86879 6.97952 4.55503 6.97952 2.93441C6.97952 1.31376 6.62439 0 4.56365 0C2.5029 0 2.14771 1.31376 2.14771 2.93441C2.14771 4.55503 3.22938 5.86879 4.56365 5.86879Z"/>
      <path d="M0.000475077 10.3492C6.36482e-05 10.2504 -0.00034778 10.3214 0.000475077 10.3492V10.3492Z"/>
      <path d="M9.12646 10.4264C9.12777 10.3994 9.12691 10.2387 9.12646 10.4264V10.4264Z"/>
      <path d="M9.12142 10.2308C9.07667 7.40765 8.70796 6.60321 5.88649 6.09399C5.88649 6.09399 5.48932 6.60009 4.56361 6.60009C3.63789 6.60009 3.24066 6.09399 3.24066 6.09399C0.449974 6.59765 0.0588426 7.39016 0.00741398 10.1391C0.00319684 10.3636 0.00124257 10.3754 0.000488281 10.3493C0.00065971 10.3981 0.000865348 10.4884 0.000865348 10.6459C0.000865348 10.6459 0.672591 12.0001 4.56361 12.0001C8.45456 12.0001 9.12635 10.6459 9.12635 10.6459C9.12635 10.5447 9.12642 10.4744 9.12652 10.4265C9.12577 10.4426 9.12426 10.4114 9.12142 10.2308Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>