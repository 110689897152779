<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 449 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M290.857 196.129C203.776 196.129 132.921 266.983 132.921 354.065C132.921 441.146 203.776 512 290.857 512C377.938 512 448.792 441.146 448.792 354.065C448.792 266.983 377.938 196.129 290.857 196.129ZM256.916 291.396C269.014 291.396 278.862 301.234 278.862 313.332C278.862 325.439 269.014 335.288 256.916 335.288C244.818 335.288 234.97 325.44 234.97 313.332C234.97 301.234 244.818 291.396 256.916 291.396ZM324.798 416.733C312.7 416.733 302.852 406.895 302.852 394.797C302.852 382.69 312.7 372.841 324.798 372.841C336.896 372.841 346.743 382.689 346.743 394.797C346.743 406.895 336.896 416.733 324.798 416.733ZM343.265 325.017L261.809 406.471C255.319 412.94 244.911 412.913 238.449 406.471C231.998 400.02 231.998 389.563 238.449 383.111L319.905 301.657C326.356 295.205 336.813 295.205 343.265 301.657C349.716 308.108 349.716 318.565 343.265 325.017ZM246.47 0H139.115C126.569 0 118.607 13.4668 124.632 24.4542L156.178 82.0232C133.714 95.3889 126.501 123.987 139.053 146.168C83.3937 165.12 40.1008 212.738 28.5292 273.032L1.50468 413.853C-8.26667 464.814 30.796 512 82.6092 512H151.398C163.828 512 169.911 496.889 160.98 488.243C55.1243 385.762 104.471 207.917 245.728 172.751C258.867 169.48 260.281 150.818 246.532 146.167C259.083 123.989 251.874 95.3889 229.407 82.0222L260.952 24.4532C266.987 13.4503 258.997 0 246.47 0V0ZM205.18 137.29H180.405C170.919 137.29 163.538 128.093 166.697 118.152C168.565 112.258 174.222 108.387 180.405 108.387H205.18C211.363 108.387 217.02 112.258 218.888 118.152C222.047 128.093 214.666 137.29 205.18 137.29Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>