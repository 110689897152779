<template>
  <Icon-Template :size="size">
    <svg viewBox="0 0 447 447" xmlns="http://www.w3.org/2000/svg">
      <path d="M231.489 446.104C265.671 441.247 295.637 405.571 314.493 352.884C287.234 346.779 259.418 343.503 231.489 343.113V446.104Z"/>
      <path d="M286.099 437.725C288.12 437.14 290.133 436.551 292.13 435.903C293.815 435.361 295.477 434.776 297.139 434.195C299.105 433.516 301.063 432.818 303.013 432.077C304.675 431.445 306.321 430.774 307.971 430.103C309.874 429.303 311.774 428.507 313.654 427.708C315.296 426.986 316.927 426.229 318.557 425.469C320.403 424.599 322.24 423.713 324.061 422.793C325.661 421.978 327.256 421.147 328.855 420.293C330.646 419.333 332.425 418.354 334.192 417.347C335.791 416.443 337.332 415.534 338.888 414.597C340.62 413.533 342.344 412.468 344.057 411.403C345.59 410.428 347.119 409.437 348.628 408.415C350.313 407.28 351.975 406.098 353.629 404.916C355.115 403.851 356.594 402.79 358.045 401.721C359.644 400.489 361.282 399.213 362.885 397.934C364.309 396.798 365.729 395.671 367.129 394.505C368.725 393.179 370.254 391.805 371.803 390.432C373.16 389.227 374.525 388.033 375.851 386.797C377.377 385.373 378.863 383.899 380.349 382.436C381.636 381.164 382.939 379.912 384.199 378.609C384.433 378.367 384.655 378.106 384.897 377.856C367.473 368.857 349.12 361.793 330.158 356.792C320.297 388.24 302.748 416.739 279.102 439.699C279.749 439.531 280.397 439.394 281.044 439.227C282.753 438.747 284.43 438.22 286.099 437.725V437.725Z"/>
      <path d="M447.001 231.041H351.214C350.852 268.441 345.426 305.619 335.085 341.564C356.172 347.197 376.522 355.295 395.714 365.69C427.224 327.712 445.261 280.361 447.001 231.041V231.041Z"/>
      <path d="M231.489 215.063H335.237C334.831 178.985 329.538 143.13 319.509 108.472C290.62 115.06 261.115 118.578 231.489 118.969V215.063Z"/>
      <path d="M231.489 0V102.991C259.418 102.597 287.234 99.3244 314.493 93.2198C295.637 40.5325 265.671 4.85642 231.489 0V0Z"/>
      <path d="M231.489 327.135C261.115 327.529 290.62 331.048 319.509 337.636C329.538 302.978 334.831 267.118 335.237 231.041H231.489V327.135Z"/>
      <path d="M395.714 80.4136C376.522 90.809 356.172 98.907 335.085 104.54C345.426 140.485 350.852 177.663 351.214 215.063H447.001C445.257 165.746 427.22 118.395 395.714 80.4136V80.4136Z"/>
      <path d="M384.921 68.2939C384.687 68.056 384.464 67.7985 384.234 67.5528C382.97 66.25 381.66 64.9939 380.372 63.7262C378.89 62.2634 377.412 60.785 375.883 59.3652C374.56 58.1325 373.191 56.9662 371.834 55.7375C370.285 54.3644 368.736 52.9797 367.145 51.6534C365.764 50.4871 364.348 49.3715 362.944 48.252C361.345 46.9569 359.706 45.6697 358.06 44.4331C356.617 43.3448 355.154 42.2916 353.684 41.2384C352.014 40.037 350.337 38.8395 348.636 37.6966C347.142 36.6902 345.625 35.7072 344.107 34.7437C342.379 33.632 340.655 32.5437 338.896 31.4905C337.355 30.566 335.799 29.6533 334.238 28.7678C332.452 27.7458 330.661 26.755 328.855 25.7876C327.256 24.988 325.661 24.1181 324.061 23.3185C322.224 22.3901 320.371 21.4968 318.472 20.6192C316.872 19.8585 315.25 19.1174 313.604 18.3957C311.712 17.5571 309.8 16.8003 307.893 16.0007C306.247 15.3298 304.608 14.6666 302.947 14.0347C301.008 13.2936 299.042 12.5992 297.076 11.9205C295.414 11.3354 293.753 10.7542 292.068 10.2081C290.07 9.56057 288.073 8.97156 286.021 8.37865C284.36 7.89106 282.69 7.39567 281.009 6.95098C280.365 6.77545 279.71 6.63892 279.062 6.47119C302.709 29.4309 320.254 57.9258 330.119 89.3774C349.1 84.3767 367.48 77.3085 384.921 68.2939V68.2939Z"/>
      <path d="M0 215.063H95.7864C96.1492 177.663 101.575 140.485 111.912 104.54C90.8286 98.9109 70.4785 90.8129 51.2869 80.4136C19.7767 118.391 1.73973 165.746 0 215.063Z"/>
      <path d="M215.512 446.104V343.113C187.583 343.507 159.766 346.779 132.508 352.884C151.36 405.571 181.33 441.247 215.512 446.104Z"/>
      <path d="M215.512 231.041H111.764C112.17 267.118 117.459 302.978 127.492 337.636C156.381 331.044 185.882 327.525 215.512 327.135V231.041Z"/>
      <path d="M215.512 0C181.33 4.85642 151.36 40.5325 132.508 93.2198C159.766 99.3283 187.583 102.601 215.512 102.991V0Z"/>
      <path d="M215.512 118.969C185.886 118.578 156.381 115.06 127.492 108.472C117.459 143.13 112.17 178.985 111.764 215.063H215.512V118.969Z"/>
      <path d="M167.876 6.46338C167.228 6.63111 166.581 6.76764 165.933 6.93537C164.24 7.38785 162.571 7.89105 160.886 8.37864C158.881 8.96375 156.891 9.55276 154.879 10.1925C153.182 10.7425 151.512 11.3276 149.843 11.9088C147.888 12.5992 145.93 13.2858 143.996 14.0191C142.326 14.6588 140.68 15.3219 139.026 16.0007C137.126 16.8003 135.223 17.6 133.347 18.3957C131.7 19.1174 130.074 19.8741 128.443 20.6347C126.598 21.5046 124.761 22.3901 122.939 23.3107C121.34 24.1259 119.741 24.9607 118.145 25.811C116.355 26.7706 114.58 27.7536 112.817 28.76C111.218 29.6532 109.646 30.5699 108.105 31.5061C106.369 32.5515 104.653 33.632 102.944 34.7008C101.407 35.6838 99.8821 36.6746 98.3725 37.6966C96.6874 38.8317 95.0257 40.0136 93.3718 41.1955C91.8856 42.2604 90.4072 43.3214 88.9523 44.3941C87.3179 45.6229 85.7576 46.9023 84.1115 48.1778C82.6916 49.313 81.26 50.4403 79.8714 51.6144C78.2721 52.9328 76.7547 54.2981 75.2139 55.6633C73.8486 56.8804 72.4717 58.0623 71.1376 59.3222C69.6202 60.7382 68.1419 62.2088 66.6596 63.6677C65.3723 64.9393 64.0617 66.1953 62.7978 67.5021C62.5677 67.7439 62.3454 68.0092 62.1035 68.2549C79.5242 77.2578 97.8811 84.322 116.843 89.3228C126.704 57.8907 144.241 29.4114 167.876 6.46338V6.46338Z"/>
      <path d="M66.6245 382.377C68.1107 383.84 69.5891 385.319 71.1143 386.742C72.4405 387.971 73.8058 389.137 75.1671 390.366C76.7157 391.743 78.2643 393.124 79.8558 394.45C81.2367 395.609 82.6448 396.728 84.0569 397.852C85.6562 399.147 87.2516 400.434 88.9445 401.678C90.3839 402.759 91.8467 403.804 93.3095 404.877C94.979 406.074 96.6641 407.272 98.3648 408.423C99.8588 409.429 101.376 410.412 102.894 411.379C104.622 412.487 106.346 413.576 108.105 414.629C109.646 415.557 111.202 416.466 112.762 417.355C114.549 418.377 116.339 419.368 118.145 420.332C119.741 421.131 121.34 422.001 122.939 422.801C124.777 423.729 126.63 424.622 128.529 425.504C130.128 426.257 131.751 426.998 133.397 427.723C135.289 428.562 137.197 429.319 139.108 430.119C140.754 430.789 142.392 431.453 144.054 432.085C145.993 432.83 147.959 433.524 149.925 434.203C151.586 434.784 153.248 435.369 154.933 435.911C156.93 436.559 158.928 437.152 160.979 437.741C162.641 438.228 164.311 438.724 165.988 439.172C166.635 439.348 167.291 439.484 167.938 439.652C144.292 416.688 126.747 388.193 116.882 356.742C97.9201 361.746 79.5633 368.811 62.1426 377.817C62.3766 378.055 62.599 378.313 62.833 378.559C64.0305 379.854 65.3217 381.11 66.6245 382.377V382.377Z"/>
      <path d="M51.2869 365.69C70.4785 355.295 90.8286 347.197 111.912 341.564C101.575 305.619 96.1492 268.441 95.7864 231.041H0C1.74363 280.358 19.7767 327.709 51.2869 365.69V365.69Z"/>
    </svg>
  </Icon-Template>
</template>

<script>

import IconTemplate from '@/components/icons/Template'

export default {
  props: ['size'],
  components: { IconTemplate }
}
</script>