var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('MB-Title',{attrs:{"text":"Declarações"}}),_c('div',{staticClass:"flex mb-8 flex-wrap"},[_c('div',{staticClass:"w-1/2 pr-4 sm:mb-8 sm:pr-0 sm:w-full"},[_c('Card',{attrs:{"header":{
          title: 'Faturamento',
          icon: 'Money'
        }}},[_c('div',{staticClass:"p-4 w-full font-light flex justify-center"},[(_vm.faturamento.loading)?_c('MB-Spinner',{attrs:{"size":"8"}}):_vm._e(),(!_vm.faturamento.loading)?_c('div',{staticClass:"flex items-center flex-col w-full"},[_c('p',[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.qtdPrevisao))]),_vm._v(" meses de previsão")]),_c('MB-Button',{staticClass:"text-sm mt-4 w-full",attrs:{"text":"Gerar Declaração"},nativeOn:{"click":function($event){return _vm.downloadDeclaracaoFaturamento.apply(null, arguments)}}}),_c('Float-List',{staticClass:"w-full mt-4",scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MB-Button',{staticClass:"text-sm w-full",attrs:{"text":"Enviar por Email"}})]},proxy:true},{key:"target",fn:function(){return [_c('div',{staticClass:"bg-neutral-200 shadow-xl flex p-6",staticStyle:{"width":"20rem"}},[_c('form',{staticClass:"w-full flex",on:{"submit":function($event){$event.preventDefault();return _vm.sendDeclaracaoFaturamentoEmail.apply(null, arguments)}}},[_c('MB-Input',{staticClass:"flex mr-4",attrs:{"placeholder":"Insira o e-mail"},model:{value:(_vm.faturamento.email),callback:function ($$v) {_vm.$set(_vm.faturamento, "email", $$v)},expression:"faturamento.email"}}),_c('MB-Button',{staticClass:"py-1",attrs:{"text":"Enviar","size":"small","loading":_vm.faturamento.loadingEmail}})],1)])]},proxy:true}],null,false,906385210)})],1):_vm._e()],1)])],1),_c('div',{staticClass:"w-1/2 pl-4 sm:pl-0 sm:w-full"},[_c('Card',{attrs:{"header":{
          title: 'Renda',
          icon: 'Money'
        }}},[_c('div',{staticClass:"flex p-4 w-full items-center flex-col font-light"},[(_vm.renda.loading)?_c('MB-Spinner',{attrs:{"size":"8"}}):_vm._e(),(!_vm.renda.loading)?_c('div',{staticClass:"flex items-center flex-col w-full"},[_c('p',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.renda.valor || 'Indisponivel'))]),_c('MB-Button',{staticClass:"text-sm mt-4 w-full",attrs:{"text":"Gerar Declaração","disabled":_vm.renda.indisponivel},nativeOn:{"click":function($event){return _vm.downloadDeclaracaoRenda.apply(null, arguments)}}}),_c('Float-List',{staticClass:"w-full mt-4",class:{ 'pointer-events-none': _vm.renda.indisponivel},attrs:{"position":"top-right"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MB-Button',{staticClass:"text-sm w-full",attrs:{"text":"Enviar por Email","disabled":_vm.renda.indisponivel}})]},proxy:true},{key:"target",fn:function(){return [_c('div',{staticClass:"bg-neutral-200 shadow-xl flex p-6",staticStyle:{"width":"20rem"}},[_c('form',{staticClass:"w-full flex",on:{"submit":function($event){$event.preventDefault();return _vm.sendDeclaracaoRendaEmail.apply(null, arguments)}}},[_c('MB-Input',{staticClass:"flex mr-4",attrs:{"placeholder":"Insira o e-mail"},model:{value:(_vm.renda.email),callback:function ($$v) {_vm.$set(_vm.renda, "email", $$v)},expression:"renda.email"}}),_c('MB-Button',{staticClass:"py-1",attrs:{"text":"Enviar","size":"small","loading":_vm.renda.loadingEmail}})],1)])]},proxy:true}],null,false,2509224004)})],1):_vm._e()],1)])],1)]),(!_vm.faturamento.loading)?_c('table',{staticClass:"mb-table"},[_vm._m(0),_c('tbody',{staticClass:"mb-table__body"},_vm._l((_vm.faturamento.items),function(faturamento){return _c('tr',{key:faturamento.id},[_c('td',[_vm._v(_vm._s(faturamento.mes))]),_c('td',[_vm._v(_vm._s(faturamento.faturamento))])])}),0)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"mb-table__header"},[_c('tr',[_c('th',[_vm._v("Mês")]),_c('th',[_vm._v("Faturamento")])])])
}]

export { render, staticRenderFns }